// Functions first
@import "../../../node_modules/bootstrap/scss/functions";

// Variable overrides second
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px
);

// Required Bootstrap imports
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/maps";
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/root";

// Optional
@import "../../../node_modules/bootstrap/scss/utilities";
@import "../../../node_modules/bootstrap/scss/reboot";
@import "../../../node_modules/bootstrap/scss/containers";
@import "../../../node_modules/bootstrap/scss/grid";
@import "../../../node_modules/bootstrap/scss/helpers";
@import "../../../node_modules/bootstrap/scss/utilities/api";
@import "../../../node_modules/bootstrap/scss/forms";
@import "../../../node_modules/bootstrap/scss/popover";
@import "../../../node_modules/bootstrap/scss/tooltip";

//Ours....
@import "_breakpoints";
@import "_colors";
@import "_mixins";
@import "_nav";
@import "_buttons";
@import "_text";
@import "_forms";
@import "marketing";

body, html{
  margin: 0;
  // background: linear-gradient(135deg, rgba(255, 255, 255, 1) 10%, rgba(4, 144, 147, 0.25) 100%);
  // background-repeat: no-repeat;
  background-color: $green-dark;
  min-height: 100vh;
  font-size: 10px;
}

div, p {
  font-size: 1.6rem;
}

.sm-txt {
  font-size: 1rem;
}

.content-wrapper {
    @include box;
}

.login {
  color: $white;
  h1 {
    color: $white;
  }
  max-width: 450px;
  margin: 0 auto;
  // @include box;
  text-align: center;
  margin-top: 5rem;
}

.box {
    @include box;
}

.clickable {
    &:hover, &:focus {
        cursor: pointer;
        text-decoration: underline;
    }
}

#content {
  padding: 2rem 0;
}

#app-wrapper {
  background: $white;
  padding: 2rem 0;
  min-height: calc(100vh - 79px);
  overflow-x: hidden;
  .empty-bee {
    width: 100%;
    height: calc(100vh - 30rem);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    img {
      width: 40vw;
      height: auto;
      max-width: 300px;
      opacity: .5;
    }
  }
}

.form-holder {
  background-color: $white;
  padding: 2rem;
  border: thin solid $green-dark;
  border-radius: 7px;
  color: $green-dark;
  .input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2rem;
    label {
        font-size: 1.2rem;
        font-weight: 400;
        text-align: left;
      }
    input {
      border-radius: 0;
      border-color: $green-forest;
      font-size: 1.4rem;
    }

    .checkbox-input-wrapper {
      display: flex;

      .form-check-label {
        padding-left: 0.5rem;
      }
    }
    
   
  }
  .submit {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .btn {
      margin: 0;
    }
  }
}

.sign-in-links {
  margin: 2rem 0 1rem 0;
  font-size: 1.4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  a {
    font-size: 1.4rem;
    color: $green-dark;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

.oauth-links {
  display: flex;
  flex-direction: column;
}

.value-chain-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
    flex: 1 1 auto;
    h1 {
      font-size: 2rem;
      margin: 0;
      padding: 0;
      margin-right: 1rem;
    }
    .selector-wrapper {
      position: relative;
      z-index: 20;
      .selector {
        font-size: 2rem;
          &:hover {
            cursor: pointer;
          }
        }
      
        .options {
          position: absolute;
          width: 100%;
          background: $white;
          z-index: 2;
          // border: thin solid $grey-8;
          margin-top: .25rem;
          .option {
            padding: .5rem 1rem;
            // border-bottom: thin solid $grey-8;
            border-left: 2px solid $grey-8;
            &:last-child {
              border-bottom: none;
            }
            &:hover {
              cursor: pointer;
              background-color: $grey-2;
            }
          }
        }
    }
  }
  .controls {
    .selector {
      font-weight: 400;
      margin-left: 2rem;
      &:first-child {
        margin-left: 0;
      }
    }

    @include respond-to-max('tablet') {
      display: none;
    }
  }
  
}

.viewer-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  .display-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    background: white;
    .display-tabs {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      span {
        margin-right: 2rem;
        font-size: 1.8rem;
        font-weight: 500;
        color: #787878;
        &.active {
          color: black;
        }
      }
    }
  }
  .controls-wrapper {
    flex-basis: 280px;
    background-color: white;
    margin-left: 3rem;
    .panel-wrapper {
        .control-panel {
          @include shadow;
          margin-bottom: 3rem;
          padding: 1rem;
          border-radius: 3px;
          h3 {
            font-weight: 300;
            color: $grey-6;
            font-size: 1.4rem;
          }
        }
        .legends {
          display: flex;
          flex-direction: column;
          .legend {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 1rem;
            .toggle {
              margin-right: 1rem;
              &:hover {
                cursor: pointer;
              }
            }
            .label {
              flex: 1 1 auto;
            }
          }
        }
      }
  }
}

.scale-bar-wrapper {
  margin-bottom: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  > * {
    width: 100%;
    padding: 1rem;
  }
  @include respond-to-max('tablet') {
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
    > * {
      width: 50%;
    }
  }
  .scale-label {
    width: 200px;
    flex: 0 0 200px;
    font-size: 1.1rem;
    padding-left: .5rem;
    text-align: right;
    padding-right: .5rem;
    line-height: 1;
    @include respond-to-max('tablet') {
      flex: 1 1 100%;
      text-align: left;
      width: calc(100% - 40px);
      //width: 100%;
      padding-left: 0rem;
    }
    span {
      display: block;
      @include respond-to-max('tablet') {
        display: inline;
      }
    }
    }
  .scale-bar {
    flex: 1 1 auto;
    margin-right: 80px;
    margin-left: 10px;
    height: 20px;
    width: 100%;
    position: relative;
    @include respond-to-max('tablet') {
      width: calc(100% - 25px);
      //width: 100%;
      margin-right: 0px;
      margin-left: 0px;
    }
    .center-line {
      position: absolute;
      top: 9px;
      left: 0;
      // border-bottom: 2px solid $green-dark;
      border-bottom: 2px solid $green-dusty;
      z-index: 1;
      &.details {
        // border-color: $grey-2;
      }
      width: 100%;
    }
    .scale-tick {
      position: absolute;
      // height: 18px;
      height: 100%;
      top: 0;
      width: 2px;
      // background-color: $green-dark;
      background-color: $green-dusty;
      z-index: 5;
      &.first {
        z-index: 1;
      }
      &.details {
        // background-color: $grey-2;
      }
    }
    .scale-values {
      padding: 0 .5rem;
      margin-top: 4.5rem;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .react-draggable {
      z-index: 4;
      .resize-handle {
        background-color: $green-forest;
        border-top: 4px solid $green-mint;
        border-left: 6px solid $green-mint;
        border-right: 6px solid $green-mint;
        border-bottom: 4px solid $green-mint;
        width: 14px !important;
        height: 20px !important;
        z-index: 5;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          top: -10px;
          left: -6px;
          width: 0;
          height: 0;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-bottom: 6px solid $green-mint;
        }
        &::after {
          content: "";
          position: absolute;
          bottom: -10px;
          left: -6px;
          width: 0;
          height: 0;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-top: 6px solid $green-mint;
        }
      }
      // &::before {
      //   width: 4px;
      //   height: 30px;
      //   background-color: $green-mint;
      //   content: '';
      //   position: absolute;
      //   left: 0;
      // }
      // &::after {
      //   width: 4px;
      //   height: 30px;
      //   // border: 2px solid $green-forest;
      //   background-color: $green-mint;
      //   content: '';
      //   position: absolute;
      //   right: 0;
      // }
    }
  }
  
}

.expanded-tranche {
  position: relative;
  padding: 2rem 0;
  margin-bottom: 2rem;
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 200vw;
    height: 100%;
    top: 0;
    left: -50vw;
    background-color: $grey-2;
    z-index: 1;
  }
  h2 {
    position: relative;
    font-size: 1.7rem;
    z-index: 2;
    margin-bottom: 2rem;
    font-weight: 600;
  }
  .expanded-emissions {
    position: relative;
    z-index: 2;
  }
}

.tranche-chart {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4rem;
  flex-wrap: wrap;
  @include respond-to-max('tablet') {
    > * {
      width: 50%;
    }
    > *:nth-child(1) {
      width: 100%;
    }
  }
  &.vertical {
    margin-bottom: 0;
    .tranche-label {
      font-size: 1.4rem;
      font-weight: 400;
    }
    .tranche-bar-holder {
      .tranche-bar-wrapper {
        .tranche-bar {
          height: 32px;
          .tick {
            background-color: $grey-6;
            height: 100%;
          }
          .marker {
            z-index: 10;
            border-color: $grey-2 !important;
            &:before {
              content: '';
              height: 2px;
              width: 200vw;
              position: absolute;
              background-color: $green-forest;
              display: block;
              z-index: 1;
              top: 11px;
              right: 24px;
            }
          }
        }
      }
    }
  }
  .tranche-label-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    @include respond-to-max('tablet') {
      display: flex;
    flex-direction: row;
    }
    .actions{
      display: none;
      @include respond-to-max('tablet') {
        display: block;
        flex-basis: 0;
      }
      .expander {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2rem;
        width: 26px;
        height: 26px;
        background-color: $grey-2;
        border-radius: 50%;
        margin-left: .7rem;
        &.active {
          background-color: $green-forest;
          color: white;
        }
      }
    }
  }
  .tranche-label {
    flex-basis: 200px;
    width: 200px;
    line-height: 1;
    margin-bottom: 1rem
    span {
      font-size: 1.2rem;
      font-weight: 400;
    }
    @include respond-to-max('tablet') {
      flex-basis: 100%;
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
  }
  .actions {
    flex-basis: 80px;
    width: 80px;
    display: flex;
    flex-direction: row;
    @include respond-to-max('tablet') {
     display: none;
    }
    .expander {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.2rem;
      width: 26px;
      height: 26px;
      background-color: $grey-2;
      // border: 1px solid $green-dark;
      border-radius: 50%;
      margin-left: .7rem;
      &.active {
        background-color: $green-forest;
        color: white;
      }
      &:hover {
        cursor: pointer;
        background-color: $green-mint-faded;
        color: $green-forest;
      }
    }
    .facilties-details-expander {
      @include respond-to-max('tablet') {
        display: none;
      }
    }
  }
  &.with-details {
    margin: 0 -1.5rem 2rem -1.5rem;
    border: thin solid #333;
    border-radius: 7px;
    @include shadow;
    padding: 2rem 1.5rem;
  }
  .header-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 2rem;
    .expander {
      font-size: 1.2rem;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
  h2 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
    &.no-hover {
      &:hover {
        cursor: text;
        text-decoration: none;
      }
    }
  }
  .tranche-bar-holder {
    position: relative;
    flex: 1 1 auto;
    .popover-bar {
      position: absolute;
      bottom: 0.5rem;
      width: 100%;
      .pop {
        position: absolute;
        z-index: 5;
        background-color: white;
        bottom: 2rem;
        border: thin solid $grey-4;
        border-radius: 7px;
        @include shadow;
        padding: .75rem;
        width: 200px;
        margin-left: -90px;
        display: flex;
        flex-direction: column;
        .label {
          font-size: 1.1rem;
          color: $grey-8;
        }
        .value {
          font-size: 1.25rem;
          color: $black;
          font-weight: 400;
        }
        &:hover {
          cursor: pointer;
          background-color: $grey-2;
        }
      }
    }
    .tranche-bar-wrapper {
      &::before {
        position: absolute;
        top: 14px;
        content: '';
        width: 100%;
        border-bottom: 2px solid $grey-2;
      }
      position: relative;
      overflow: hidden;
      width: 100%;
      padding: 0 10px;
      // background-color: $green-mint-faded;
      // border-radius: 3px;
      .sub-emmission-bg {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background: #6DC96F;
      }
      .tranche-bar {
        position: relative;
        height: 28px;
        left: 0px;
        width: calc(100% - 20px);
        .tick {
          pointer-events: none;
          width: 2px;
          height: 28px;
          position: absolute;
          top: 0;
          background-color: $grey-2;
        }
        .marker {
          width: 28px;
          height: 28px;
          // top: 6px;
          position: absolute;
          margin-left: -10px;
          border: 2px solid $green-forest;
          background-color: white;
          border-radius: 50%;
          &.solid {
            background-color: $green-dark;
            border: 2px solid white;
            &.green {
              background-color: $green-mint;
              z-index: 10;
            }
          
            &.blue {
              background-color: $highlight-blue;
              z-index: 10;
            }
          
            &.red {
              background-color: $highlight-red;
              z-index: 10;
            }
          
            &.yellow {
              background-color: $highlight-yellow;
              z-index: 10;
            }
          }
          &.green {
            background-color: $green-mint;
          }
          &.blue {
            background-color: $highlight-blue;
          }
          &.red {
            background-color: $highlight-red;
          }
          &.yellow {
            background-color: $highlight-yellow;
          }
          &:hover {
            cursor: pointer;
          }
          &:before {
            content: "";
            display: none;
            position: absolute;
            right: 16px;
            top: 9px;
            width: 1000vw;
            height: 1px;
            background: $green-forest;
          }
          img {
            margin: 0;
            padding: 0;
            vertical-align: top;
            height: 16px;
            opacity: 1;
          }
        }
        &.active {
          height: auto;
          padding: 2px 0;
          .marker {
            // padding-top: 2px;
            height: 24px;
            position: relative;
            &:before {
              display: block;
            }
          }
        }
      }
    }
  }
}

.tranche-holder{
  margin: 3rem 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  .tranche-chart {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100px;
      text-align: center;
  
      .bar {
        width: 80px;
        height: 300px;
        border-radius: 3px;
        background: #EDF5FF;
      }
    }
}

.tranche-details {
  position: relative;
  margin-top: -2rem;
  margin-bottom: 2rem;
  padding: 2rem 0;
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 200vw;
    height: 100%;
    top: 0;
    left: -50vw;
    background-color: $green-forest;
    z-index: 1;
  }
  .detail-content {
    position: relative;
    z-index: 2;
    color: white;
    .scale-values {
      color: white;
    }
  }
  .tranche-chart {
    .tranche-label {
      font-size: 1.4rem;
      font-weight: 400;
    }
    .tranche-bar-holder {
      .tranche-bar-wrapper {
          // background-color: #6DC96F;
          &::before {
            border-bottom-color: $green-dusty;
          }
          .tranche-bar {
            .tick {
              background-color: $green-dusty;
            }
            .marker {
              background-color: $green-dark;
              border-color: $green-forest !important;
              &.green {
                background-color: $green-mint;
              }
              &.blue {
                background-color: $highlight-blue;
              }
              &.red {
                background-color: $highlight-red;
              }
              &.yellow {
                background-color: $highlight-yellow;
              }
            }
          }
        }
    }
  } 
}

.active-facilities-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  .tranche-name {
    flex: 0 0 200px;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.2;
  }
  .actives {
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .toggle-btn {
      margin-right: 1rem;
      width: fit-content;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: .3rem 1rem;
      font-size: 1.2rem;
      border-radius: 4px;
      color: $green-forest;
      font-weight: 400;
      background-color: $grey-2;
      .closer {
        display: flex;
        margin-left: .7rem;
        &:hover {
          cursor: pointer;
        }
      }
      &.green {
        background-color: $green-mint;
        z-index: 10;
      }
      &.blue {
        background-color: $highlight-blue;
        z-index: 10;
      }
      &.red {
        background-color: $highlight-red;
        z-index: 10;
      }
      &.yellow {
        background-color: $highlight-yellow;
        z-index: 10;
      }
    }
  }
}

.table {
  &.facilities {
    width: 100%;
    tr {
      height: 3rem;
    }
    th {
      font-weight: 400;
      font-size: 1.2rem;
    }
    .emission-value {
      font-weight: 400;
    }
    .active-facility {
      border-left: 3px solid $grey-2;
      padding-left: 1rem;
      &.green {
        border-color: $green-mint;
        z-index: 10;
      }
      &.blue {
        border-color: $highlight-blue;
        z-index: 10;
      }
      &.yellow {
        border-color: $highlight-yellow;
        z-index: 10;
      }
      &.red {
        border-color: $highlight-red;
        z-index: 10;
      }
    }

  }
}

.verify-bar {
  background-color: $grey-2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  .btn {
    margin: 1rem auto;
    padding: .75rem 1.25rem;
  }
}

.router-msg {
  color: white;
  margin: 2rem auto;
}