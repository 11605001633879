#marketing-wrapper {
    #home {
        margin: auto;
        min-height: 50vh;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        margin-top: 10vh;
        @include respond-to-max('laptop') {
            flex-direction: column;
            h1 {
                text-align: center;
            }
            p {
                text-align: center;
            }

        }
        h1 {
            color: $grey-2;
        }
        .half {
            flex: 1 1 auto;
            padding: 2rem;
            width: 100%;
            p {
                color: $grey-2;
                font-weight: 400;
            }
            .form-holder {
                p {
                    color: $green-forest;
                }
            }
            // .form-wrapper {
            //     background-color: white;
            //     border-radius: 7px;
            //     padding: 2rem;
            // }
        }
    }
}