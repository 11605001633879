.menu {
    height: 5rem;
    padding: 2.5rem 0rem .5rem .5rem;
    position: relative;
    z-index: 10;
    .wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
        img {
            height: 4rem;
        }
        .links {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            a {
                font-size: 1.6rem;
                font-weight: 400;
                padding: .5rem;
                margin: 0 1rem;
                display: block;
                color: $grey-2;
                height: 100%;
                text-decoration: none;
                border-bottom: 2px solid transparent;
                &.active, &:hover, &:focus {
                    color: $green-mint;
                    border-bottom: 2px solid $green-mint;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
            .btn {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }
}

.page-header {
    width: 100%;
    background-color: $green-dark;
    padding: 1rem 0;
    border-bottom: thin solid $green-forest;
    .container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .logo-holder {
            display: flex;
            flex-direction: column;
            align-items: center;
            .tagline {
                font-size: 1.5rem;
                letter-spacing: .05em;
                font-weight: 400;
                color: $grey-2;
            }
        }
    }
}
