@import "_colors";

@mixin flexWidth($widths...) {
    @each $width in $widths {
        &.flex-#{round($width)} {
            flex-basis: #{percentage($width/100)};
        }
    }
}

@mixin shadow {
    box-shadow: rgba(77,77,77,.2) 1px 1px 15px;
}

@mixin box {
  background-color: white;
  @include shadow;
  padding: 3rem 2rem;
}