form {
    ::placeholder {
        color: $grey-6 !important;
    }
    .form-group {
        text-align: left;
        margin-bottom: 1rem;
        label {
            display: block;
            text-transform: uppercase;
            font-weight: 400;
            color: $grey-6;
            letter-spacing: .05em;
        }
        .form-control {
            font-size: 1.6rem;

        }
        
    }
        .checkbox-input-wrapper {
            .form-check-input {
                margin-top: .15rem;
            }
        }
    .invalid-feedback {
        display: none;
    }
}