/* from cordina brand guide */
$green-dark: #17373c;
$green-forest: #225455;
$green-mint: #64db89;
$green-mint-faded: rgba(100, 219, 137, .33);
// $green-dusty: #51696C;
$green-dusty: #597F80;

$black: #0F110F;
$grey-8: #2E302E;
$grey-6: #B0B0B0;
$grey-4: #B4BEB4;
$grey-2: #ECECEC;
$white: #FFFFFF;

$highlight-red: #FF6554;
$highlight-blue: #00AEEF;
$highlight-yellow: #FFB13D;

.white {
    color: white;
    &:hover {
        color: white;
    }
}



