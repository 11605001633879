.btn {
    font-size: 1.5rem;
    line-height: 1;
    font-weight: 600;
    letter-spacing: .05em;
    border: 1px solid $green-forest;
    color: $green-forest;
    padding: 1.25rem 2.5rem;
    margin: 1em auto;
    display: inline-block;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    border-radius: 2px;
    text-decoration: none;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    &:hover, &:focus {
        background-color: $green-forest;
        color: white;
        text-decoration: none;
        cursor: pointer;
    }
    &.white-outline {
        background: transparent;
        color: white;
        border-color: white;
        &:hover {
            background-color: white;
            color: $green-forest;
        }
    }

    &.white {
        background-color: white;
        border-color: white !important;
        color: $green-forest !important;
        font-weight: 700;
        &:hover {
            background-color: $grey-2;
            border-color: $green-mint !important;
        }
    }
    &.green {
        background-color: $green-forest;
        border-color: $green-forest !important;
        color: white !important;
        font-weight: 700;
        &:hover {
            background-color: white;
            // border-color: agua !important;
            color: $green-forest !important;
        }
    }
    &.mint {
        background-color: $green-mint;
        border-color: $green-mint !important;
        color: $green-forest !important;
        font-weight: 700;
    
        &:hover {
            background-color: white;
            // border-color: agua !important;
            color: $green-forest !important;
        }
    }
    &.grey {
        background-color: $grey-6;
        border-color: $grey-6 !important;
        color: $grey-8;
        font-weight: 700;
        &:hover {
            background-color: $grey-6;
            border-color: $green-forest !important;
            color: $green-forest !important;
        }
        &.active {
            color: white;
            background-color: $green-forest;
            border-color: $green-forest !important;
            &:hover {
                background-color: $grey-6;
            }

        }
    }
    &:disabled {
        background: $grey-4;
        border-color: $grey-4 !important;
        color: $grey-6 !important;
        &:hover {
            cursor: default;
            background: $grey-4;
            color: $grey-6 !important;
        }
    }
}
